<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row :gutter="10" class="top_l">
					<el-col :span="24" :xs="24" class="elel">
						<el-button type="primary" @click="dialogAdd = true">新增</el-button>
						<div class="readbox">
							<el-tag size="large">查询是否可用：</el-tag>
							<el-radio-group v-model="status" @change="radioes">
								<el-radio label="" border>全部</el-radio>
								<el-radio :label="0" border>是</el-radio>
								<el-radio :label="1" border>否</el-radio>
							</el-radio-group>
						</div>
					</el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border
					stripe>
					<el-table-column label="经验奖励场景" align="center">
						<template v-slot="scope">
							<span v-if="scope.row.type == 1">看视频解锁剧集</span>
							<span v-if="scope.row.type == 2">抽奖</span>
							<span v-if="scope.row.type == 3">提现位置打开奖励</span>
							<span v-if="scope.row.type == 4">升级加速位置</span>
							<span v-if="scope.row.type == 5">在线时长经验</span>
							<span v-if="scope.row.type == 6">刷视频白嫖经验</span>
						</template>
					</el-table-column>
					<el-table-column prop="currentExpMin" label="当前经验值区间最小值" align="center"></el-table-column>
					<el-table-column prop="currentExpMax" label="当前经验值区间最大值" align="center"></el-table-column>
					<el-table-column prop="rewardExpMin" label="奖励经验值区间最小值" align="center"></el-table-column>
					<el-table-column prop="rewardExpMax" label="奖励经验值区间最大值" align="center"></el-table-column>
					<el-table-column prop="upperLimit" label="当日奖励上限" align="center"></el-table-column>
					<el-table-column label="是否可用" align="center">
						<template v-slot="scope">
							<el-switch @change="switchClick(scope.row)" v-model="scope.row.disabled" class="mb-2"
								style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="0"
								:inactive-value="1" active-text="是" inactive-text="否" />
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="创建时间" align="center"></el-table-column>
					<el-table-column prop="upgradeTime" label="更新时间" align="center"></el-table-column>
					<el-table-column label="操作" align="center" width="100">
						<template v-slot="scope">
							<el-button size="small" type="primary" plain @click="clcedit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
						:total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
				</div>
				<!-- 新增-配置 -->
				<el-dialog v-model="dialogAdd" title="新增配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="180px" class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="经验奖励场景" prop="type">
									<el-select v-model="ruleForm.type" clearable placeholder="请选择经验奖励场景"
										@change="selectClk(1)">
										<el-option label="看视频解锁剧集" :value="1" />
										<el-option label="抽奖" :value="2" />
										<el-option label="提现位置打开奖励" :value="3" />
										<el-option label="升级加速位置" :value="4" />
										<el-option label="在线时长经验" :value="5" />
										<el-option label="刷视频白嫖经验" :value="6" />
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="当前经验值区间最小值" prop="currentExpMin">
									<el-input-number v-model="ruleForm.currentExpMin" :step="1" :min="0"
										placeholder="请输入当前经验值区间最小值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="当前经验值区间最大值" prop="currentExpMax">
									<el-input-number v-model="ruleForm.currentExpMax" :step="1" :min="0"
										placeholder="请输入当前经验值区间最大值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="奖励经验值区间最小值" prop="rewardExpMin">
									<el-input-number v-model="ruleForm.rewardExpMin" :step="1" :min="0"
										placeholder="请输入奖励经验值区间最小值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="奖励经验值区间最大值" prop="rewardExpMax">
									<el-input-number v-model="ruleForm.rewardExpMax" :step="1" :min="0"
										placeholder="请输入奖励经验值区间最大值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="当日奖励上限" prop="upperLimit">
									<el-input-number v-model="ruleForm.upperLimit" :step="1" :min="0"
										placeholder="请输入当日奖励上限" />
								</el-form-item>
							</el-col>

						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
							<el-button @click="resetClick('addruleForm')">重置</el-button>
						</span>
					</template>
				</el-dialog>
				<!-- 修改-配置 -->
				<el-dialog v-model="dialogEdit" title="修改配置" width="50%" :fullscreen="fullscreenshow">
					<el-form :model="EditruleForm" :rules="rules" ref="EditruleFormRef" label-width="180px"
						class="demo-ruleForm">
						<el-row :gutter="20">
							<el-col :span="12" :xs="24">
								<el-form-item label="经验奖励场景" prop="type">
									<el-select v-model="EditruleForm.type" clearable placeholder="请选择经验奖励场景"
										@change="selectClk(2)">
										<el-option label="看视频解锁剧集" :value="1" />
										<el-option label="抽奖" :value="2" />
										<el-option label="提现位置打开奖励" :value="3" />
										<el-option label="升级加速位置" :value="4" />
										<el-option label="在线时长经验" :value="5" />
										<el-option label="刷视频白嫖经验" :value="6" />
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="当前经验值区间最小值" prop="currentExpMin">
									<el-input-number v-model="EditruleForm.currentExpMin" :step="1" :min="0"
										placeholder="请输入当前经验值区间最小值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="当前经验值区间最大值" prop="currentExpMax">
									<el-input-number v-model="EditruleForm.currentExpMax" :step="1" :min="0"
										placeholder="请输入当前经验值区间最大值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="奖励经验值区间最小值" prop="rewardExpMin">
									<el-input-number v-model="EditruleForm.rewardExpMin" :step="1" :min="0"
										placeholder="请输入奖励经验值区间最小值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="奖励经验值区间最大值" prop="rewardExpMax">
									<el-input-number v-model="EditruleForm.rewardExpMax" :step="1" :min="0"
										placeholder="请输入奖励经验值区间最大值" />
								</el-form-item>
							</el-col>
							<el-col :span="12" :xs="24">
								<el-form-item label="当日奖励上限" prop="upperLimit">
									<el-input-number v-model="EditruleForm.upperLimit" :step="1" :min="0"
										placeholder="请输入当日奖励上限" />
								</el-form-item>
							</el-col>

						</el-row>
					</el-form>
					<template #footer>
						<span class="dialog-footer">
							<el-button type="primary" @click="EditsubmitForm('EditruleFormRef')">提交</el-button>
							<el-button @click="dialogEdit = false">取消</el-button>
						</span>
					</template>
				</el-dialog>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'experienceRewards',
	data() {
		return {
			fullscreenshow: false,
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			status: '',
			// 新增配置
			dialogAdd: false,
			ruleForm: {
				type: '',
				comment: '',
				currentExpMin: null,
				currentExpMax: null,
				rewardExpMin: null,
				rewardExpMax: null,
				upperLimit: null,
			},
			rules: {
				currentExpMin: [{
					required: true,
					message: '请输入当前经验值区间最小值',
					trigger: 'blur'
				}],
				currentExpMax: [{
					required: true,
					message: '请输入当前经验值区间最大值',
					trigger: 'blur'
				}],
				rewardExpMin: [{
					required: true,
					message: '请输入奖励经验值区间最小值',
					trigger: 'blur'
				}],
				rewardExpMax: [{
					required: true,
					message: '请输入奖励经验值区间最大值',
					trigger: 'blur'
				}],
				upperLimit: [{
					required: true,
					message: '请输入当日奖励上限',
					trigger: 'blur'
				}],
				type: [{
					required: true,
					message: '请选择经验奖励场景',
					trigger: 'change'
				}],

			},
			// 编辑
			dialogEdit: false,
			EditruleForm: {
				id: '',
				type: '',
				comment: '',
				currentExpMin: null,
				currentExpMax: null,
				rewardExpMin: null,
				rewardExpMax: null,
				upperLimit: null,
			}
		};
	},
	created() {
		if (document.body.clientWidth < 768) {
			this.fullscreenshow = true;
		} else {
			this.fullscreenshow = false;
		}

		this.productId = this.$TOOL.data.get('DATA_SELECTEED')
		// 获取配置
		this.SignInConfig();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval
				// 获取配置
				this.SignInConfig();
			}
		}
	},
	methods: {
		// 获取配置
		SignInConfig() {
			this.serloading = true;
			this.$HTTP.post(
				'dramaExpRewardConfig/query', {
				disabled: this.status,
				productId: this.productId,
				pageNumber: this.currentPage,
				pageSize: this.pageSize
			},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 重置
		resetClick(name) {
			this.$refs[name].resetFields();
		},
		//查新是否可用
		radioes() {
			this.currentPage = 1;
			this.SignInConfig();
		},
		// 是否可用
		switchClick(row) {
			this.$HTTP.post(
				'dramaExpRewardConfig/updateStatus', {
				id: row.id,
				disabled: row.disabled
			},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.$message.success(response.data.msg);
							// 获取配置
							this.SignInConfig();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 奖励场景选中
		selectClk(od) {
			if (od == 1) {
				if (this.ruleForm.type == 1) {
					this.ruleForm.comment = '看视频解锁剧集'
				} else if (this.ruleForm.type == 2) {
					this.ruleForm.comment = '抽奖'
				} else if (this.ruleForm.type == 3) {
					this.ruleForm.comment = '提现位置打开奖励'
				} else if (this.ruleForm.type == 4) {
					this.ruleForm.comment = '升级加速位置'
				} else if (this.ruleForm.type == 5) {
					this.ruleForm.comment = '在线时长经验'
				} else if (this.ruleForm.type == 6) {
					this.ruleForm.comment = '刷视频白嫖经验'
				}
			} else {
				if (this.EditruleForm.type == 1) {
					this.EditruleForm.comment = '看视频解锁剧集'
				} else if (this.EditruleForm.type == 2) {
					this.EditruleForm.comment = '抽奖'
				} else if (this.EditruleForm.type == 3) {
					this.EditruleForm.comment = '提现位置打开奖励'
				} else if (this.EditruleForm.type == 4) {
					this.EditruleForm.comment = '升级加速位置'
				} else if (this.EditruleForm.type == 5) {
					this.EditruleForm.comment = '在线时长经验'
				} else if (this.EditruleForm.type == 6) {
					this.EditruleForm.comment = '刷视频白嫖经验'
				}
			}

		},
		// 新增-配置
		submitClick(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'dramaExpRewardConfig/add', {
						type: this.ruleForm.type,
						comment: this.ruleForm.comment,
						currentExpMin: this.ruleForm.currentExpMin,
						currentExpMax: this.ruleForm.currentExpMax,
						rewardExpMin: this.ruleForm.rewardExpMin,
						rewardExpMax: this.ruleForm.rewardExpMax,
						upperLimit: this.ruleForm.upperLimit,
						productId: this.productId
					},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogAdd = false;
									this.$message.success(response.data.msg);
									this.resetClick(formName);
									// 获取配置
									this.SignInConfig();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},
		// 编辑
		clcedit(row) {
			this.EditruleForm.type = row.type;
			this.EditruleForm.comment = row.comment;
			this.EditruleForm.currentExpMax = row.currentExpMax;
			this.EditruleForm.currentExpMin = row.currentExpMin;
			this.EditruleForm.rewardExpMin = row.rewardExpMin;
			this.EditruleForm.rewardExpMax = row.rewardExpMax;
			this.EditruleForm.upperLimit = row.upperLimit;
			this.EditruleForm.id = row.id;
			this.dialogEdit = true;
		},
		EditsubmitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.$HTTP.post(
						'dramaExpRewardConfig/update', {
						id: this.EditruleForm.id,
						type: this.EditruleForm.type,
						comment: this.EditruleForm.comment,
						currentExpMax: this.EditruleForm.currentExpMax,
						currentExpMin: this.EditruleForm.currentExpMin,
						rewardExpMin: this.EditruleForm.rewardExpMin,
						rewardExpMax: this.EditruleForm.rewardExpMax,
						upperLimit: this.EditruleForm.upperLimit
					},
						response => {
							if (response.status >= 200 && response.status < 300) {
								if (response.data.code == 0) {
									this.dialogEdit = false;
									this.$message.success(response.data.msg);
									// 获取配置
									this.SignInConfig();
								} else {
									this.$message.error(response.data.msg);
								}
							} else {
								console.log(response.message);
							}
						}
					);
				} else {
					return false;
				}
			});
		},

		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取配置
			this.SignInConfig();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取配置
			this.SignInConfig();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-input-number {
	width: 100%;
}

.readbox {
	display: inline-block;
	margin-left: 20px;

	span {
		margin-right: 10px;
		font-size: 16px;
	}
}

.elel {
	display: flex;
	align-items: center;
}
</style>